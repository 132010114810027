import React, { useEffect, useState } from "react";
import "./App.css";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  redirect,
  Link,
} from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import BetJuicer from "./components/bet-juicer/BetJuicer";
import BetFinder from "./components/bet-finder/BetFinder";
//import SureBets from "./components/sure-bets/sure-bets-card/SureBetsOld";
import ComingSoonScreen from "./components/commons/coming-soon";
import ParlayBuilder from "./components/parlay-builder/ParlayBuilder";
import GoogleSignInButton from "./components/login/GoogleSignInButton";
import jwt_decode from "jwt-decode";
import { isConstructorDeclaration } from "typescript";
import { Role, stringToRole, User } from "./components/types/User";
import { GoogleOAuthProvider } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import {
  getAccessToken,
  getCurrentUser,
  getIsAuthenticated,
  getUserRole,
  isTokenExpired,
  logout,
  setProfilePic,
  setUserRole,
} from "./services/apiService";
import { DecodedToken } from "./components/types/DecodedToken";
//import { AuthProvider } from "./components/login/AuthContext";
//import AuthWrapper from "./components/login/AuthWrapper";
import DarkMode from "./components/commons/DarkMode/DarkMode";
import { getDescriptionByComponent } from "./constants";
//import PricingPage from "./components/payments/PricingPage";
import AccountSettings from "./components/account/AccountSettings";
import Overview from "./components/account/Overview";
import ManageFilters from "./components/account/ManageFilters";
import CustomerPortal from "./components/account/CustomerPortal";
import { useUser, UserProvider } from "./components/login/UserContext";
//import { defaultUser } from "./components/login/defaultUser";
import AccessControl from "./components/login/AccessControl";
import Util from "./components/utilities/Util";
import SureBetsOld from "./components/sure-bets/sure-bets-card/SureBetsOld";
import SureBets from "./components/sure-bets/sure-bets-card/SureBets";
import SureBetSection from "./components/sure-bets/sure-bets-card/SureBetSection";
import Login from "./components/login/Login";
import { useDarkMode } from "./components/commons/DarkMode/useDarkMode";
import LandingPage from "./components/landing-page/LandingPage";
import { defaultUser } from "./components/login/defaultUser";
import OnlySignIn from "./components/utilities/OnlySignIn";
import SuggestionWall from "./components/suggestion-wall/SuggestionWall";
import RedirectToLogin from "./components/commons/RedirectToLogin";
import NavbarHandler from "./hooks/NavbarHandler";
import AuthenticationButton from "./components/login/AuthenticationButton";
import SignInModal from "./components/login/SignInModal";
import Page from "./components/products/positive-ev/positive-ev";
import Disclaimer from "./components/landing-page/footer/disclaimer";
import PositiveEV from "./components/products/positive-ev/positive-ev";
import {
  currentUserHasEVAccess,
  roleHasPositiveEVAccess,
} from "./components/login/hasAccess";
import PositiveEVUnAuthed from "./components/products/positive-ev/positive-ev-unauthed";
import NewSubscriber from "./components/products/positive-ev/components/new-subscriber/new-subscriber";
import { EVTableHeader } from "./components/products/positive-ev/positive-ev-header";
import EVTip from "./components/products/positive-ev/positive-ev-tips";
import PositiveEVRoute from "./components/products/positive-ev/positive-ev-route";
import ContentPostView from "./components/content/content-post/content-post-view";
import NotFound from "./components/not-found/not-found";
import BettingEducation from "./components/content/betting-education/betting-education";
import BrawlerBlog from "./components/content/blog/brawler-blog";
import { Helmet, HelmetProvider } from "react-helmet-async";
import BettingCalculators from "./components/content/betting-calculators/betting-calculators";
import CalculatorContent from "./components/content/betting-calculators/calculator-content";
import Directory from "./components/landing-page/footer/directory";
import ArbitrageComingSoon from "./components/products/arbitrage/arbitrage-coming-soon";
import { AllPromos } from "./components/products/promo-finder/promo-finder-full";

function App() {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? "";
  const navbarHeight = "64px";

  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(0);
  // const [authCheckFinished, setAuthCheckFinished] = useState(false);
  // const [isInitialRender, setIsInitialRender] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const [usrState, setUsrState] = useState<User>();
  const [showLoginModal, setShowLoginModal] = useState(false);
  //const location = useLocation();

  const toggleModal = () => {
    setShowLoginModal(!showLoginModal);
  };

  const { user, setUser, token, setToken, refreshToken, setRefreshToken } =
    useUser();

  const handleLogout = () => {
    logout();
    setIsUserAuthenticated(false);
    // setToken(null);
    // setRefreshToken(null);

    setUser(defaultUser);
  };

  const handleLogin = () => {
    setShowLoginModal(false);
    setIsUserAuthenticated(true);
    //window.location.reload();
    window.location.href = "/positive-ev";
  };

  // useEffect(() => {
  //   const checkAuthentication = async () => {
  //     const authStatus = await getIsAuthenticated();
  //     // console.log("Auth status,: ", authStatus); // Added for debugging
  //    // setIsUserAuthenticated(authStatus);
  //     setAuthCheckFinished(true);
  //     if (authStatus) {
  //       const currentUser = await getCurrentUser();
  //       if (currentUser && currentUser.data) {
  //         const user = currentUser.data;
  //         setUser(user);
  //         setCurrentUserId(user.id);
  //         // setProfilePic(user.picture);
  //         // setUserRole(user.role);
  //       }
  //     }
  //     setIsInitialRender(false);
  //   };

  //   checkAuthentication();
  // }, []);

  useEffect(() => {
    const updateUserContext = async () => {
      const response = await getCurrentUser();
      const currentUser = response?.data;
      const authed = await getIsAuthenticated();
      if (currentUser && authed) {
        setProfilePic(currentUser.picture);
        setUserRole(currentUser.role);
      }
    };

    updateUserContext();
  }, []);

  useEffect(() => {
    const setUserState = async () => {
      const response = await getCurrentUser();
      const currentUser = response?.data;
      setUsrState(currentUser);
    };

    setUserState();
  }, []);

  const authed = usrState ? true : false;

  return (
    <HelmetProvider>
      <UserProvider>
        <GoogleOAuthProvider clientId={clientId}>
          <Router>
            <Helmet>
              <title>BookieBrawler - Positive EV Bet Finder</title>
              <meta
                name="description"
                content="At BookieBrawler, we're revolutionizing the way you approach sports betting. Our cutting-edge Sports Betting Tools are engineered to shift the odds in your favor, transforming betting from a game of chance to a calculated strategy. Dive into our suite of tools, including the highly acclaimed Positive EV (Expected Value) Bet Finder, and get ready for the launch of our groundbreaking Arbitrage and Social Community Platform. Designed by seasoned bettors for the betting community, BookieBrawler is your go-to resource for leveraging free bets, gaining unparalleled insights, and standing strong against Sportsbooks."
              />
            </Helmet>

            <header className="sticky-navbar">
              {/* Navbar content */}
              <NavbarHandler setIsScrolled={setIsScrolled} />
              <div
                className={`header-bg d-flex align-items-center justify-content-center ${
                  isScrolled ? "scrolled light-btm-brder-thin" : ""
                }`}
              >
                <div className="logo-container">
                  <Link to="/">
                    <img
                      className="logo h-[70px] w-auto"
                      src="https://storage.googleapis.com/assestsbookiebrawler/logos/brawler-text-logo-transparent-final.svg"
                      alt="BookieBrawler"
                    />
                  </Link>
                </div>

                <div className="far-right">
                  <AuthenticationButton
                    onLogout={handleLogout}
                    toggleModal={toggleModal}
                  />
                  <SignInModal
                    isAuthenticated={isUserAuthenticated}
                    show={showLoginModal}
                    toggleModal={toggleModal}
                    onLogin={handleLogin}
                  />
                </div>
              </div>
            </header>

            <div className="page-content">
              <div
                className="content-container"
                style={{
                  paddingTop:
                    window.location.pathname == "/" ? 0 : navbarHeight,
                }}
              >
                <Routes>
                  {/* <Route
            path="/"
            element={
              <OnlySignIn
                onLogin={setIsUserAuthenticated}
                onLogout={handleLogout}
                isAuthenticated={isUserAuthenticated}
              />
            }
          /> */}
                  {/* <Route
                path="/home"
                element={
                  <BetFinder isUserAuthenticated={isUserAuthenticated} />
                }
              /> */}
                  {/* <Route
                path="/bets"
                element={
                  <AccessControl
                    component={SureBetSection}
                    name="SureBetSection"
                  />
                }
              /> */}
                  {/* <Route 
                path="/parlays"
                element={
                  <AccessControl
                    component={ParlayBuilder}
                    name="ParlayBuilder"
                  />
                }
              /> */}
                  {/* <Route
                path="/juicer"
                element={
                  <AccessControl component={BetJuicer} name="BetJuicer" />
                }
              /> */}
                  {/* <Route path="/subscribe" element={<PricingPage />} /> */}
                  <Route
                    path="/account-settings/*"
                    element={<AccountSettings />}
                  />
                  <Route
                    path="/login"
                    element={
                      <Login
                        onLogin={setIsUserAuthenticated}
                        isAuthenticated={isUserAuthenticated}
                        onLogout={handleLogout}
                        redirectedFrom={""}
                        toggleModal={toggleModal}
                      />
                    }
                  />
                  <Route path="/" element={<LandingPage />} />
                  <Route path="*" element={<NotFound />} />
                  <Route
                    path="/positive-ev"
                    element={<PositiveEVRoute authed={authed} />}
                  />

                  <Route
                    path="/positive-ev/tips"
                    element={
                      <div className="p-5">
                        <EVTip />
                      </div>
                    }
                  />
                  <Route
                    path="/ev-welcome"
                    element={<NewSubscriber authed={authed} />}
                  />
                  <Route
                    path="/arbitrage"
                    element={<ArbitrageComingSoon authed={authed} />}
                  />
                  <Route
                    path="/sportsbook-promo-finder"
                    element={<AllPromos />}
                  />

                  {/* <Route
                path="/account-settings/overview"
                element={<Overview initialUser={ usrState }/>}
              /> */}
                  {/* <Route
                nd"/account-settings/customer-portal"
                element={<CustomerPortal />}
              /> */}
                  {/* <Route
                    path="/admin/utils"
                    element={<AccessControl component={Util} name="Util" />}
                  /> */}

                  <Route path="/blog" element={<BrawlerBlog />} />
                  <Route
                    path="/blog/:slug"
                    element={<ContentPostView contentType="Blog" />}
                  />
                  <Route
                    path="/betting-calculators"
                    element={<BettingCalculators />}
                  />
                  <Route
                    path="/betting-calculators/:slug"
                    element={<CalculatorContent />}
                  />
                  <Route
                    path="/betting-education"
                    element={<BettingEducation />}
                  />
                  <Route
                    path="/betting-education/:slug"
                    element={
                      <ContentPostView contentType="Betting Education" />
                    }
                  />
                  <Route
                    path="/online-sportsbooks/:slug"
                    element={
                      <ContentPostView contentType="Online Sportsbooks" />
                    }
                  />

                  <Route
                    path="/mlb/:slug"
                    element={<ContentPostView contentType="MLB Blog" />}
                  />

                  <Route
                    path="/nba/:slug"
                    element={<ContentPostView contentType="NBA Blog" />}
                  />

                  <Route
                    path="/casino/:slug"
                    element={<ContentPostView contentType="Casino Blog" />}
                  />

                  <Route
                    path="/suggestions"
                    element={
                      // authCheckFinished && currentUserId !== 0 ? (
                      <AccessControl
                        component={SuggestionWall}
                        name="SuggestionWall"
                        userId={currentUserId}
                      />
                    }
                  />

                  {/* <Route
                path="/account-settings/manage-filters"
                element={<ManageFilters />}
              /> */}
                </Routes>
              </div>
            </div>
            <div className="bg-gradient-main">
              <Directory />
              <Disclaimer />
            </div>
          </Router>
        </GoogleOAuthProvider>
      </UserProvider>
    </HelmetProvider>
  );
}

export default App;
