import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GoogleButtonLogin from "./GoogleButtonLogin";
import styles from "./login.module.scss";
import { useUser } from "./UserContext";
import ReactPlayer from "react-player";
import useAudio from "../../hooks/useAudio";
import {
  getCurrentUser,
  getIsAuthenticated,
  getRequest,
} from "../../services/apiService";
import { User } from "../types/User";
import { getRandomImage } from "../commons/backgroundImageUtil";
import { Helmet } from "react-helmet";

interface LoginProps {
  onLogin: (isAuthenticated: boolean) => void;
  isAuthenticated: boolean;
  onLogout: () => void;
  redirectedFrom: string;
  toggleModal: () => void;
}

const mp3path =
  "https://storage.googleapis.com/assestsbookiebrawler/music/VENUE140%20%5BNIGHHTFALL%5D%20(1).mp3";

const Login: React.FC<LoginProps> = ({
  onLogin,
  isAuthenticated,
  onLogout,
  redirectedFrom,
  toggleModal,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [currentUser, setCurrentUser] = useState({} as User);

  const { user, setUser, token, setToken, refreshToken, setRefreshToken } =
    useUser();

  const finalUser = currentUser.email ? currentUser : user;

  const { playing, toggle } = useAudio({ url: mp3path });

  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
  const [totalUsers, setTotalUsers] = useState("0");
  const [userRank, setUserRank] = useState("0");

  // const backgroundImages = [
  //   "https://storage.googleapis.com/assestsbookiebrawler/background-images/loginbackgrounds/bloohunnuits_blue_office_aesthetic_photorealistic_1cae03bf-67f8-4157-a002-2af938701ff5.png",
  //   "https://storage.googleapis.com/assestsbookiebrawler/background-images/loginbackgrounds/bloohunnuits_blue_office_aesthetic_quality_film_type_661c1c05-1d91-4392-88a7-ff871e91f793.png",
  //   "https://storage.googleapis.com/assestsbookiebrawler/background-images/loginbackgrounds/bloohunnuits_blue_office_aesthetic_quality_film_type_cebfed4f-aa55-4fa8-bb64-dc00de931c80.png",
  //   //"https://storage.googleapis.com/assestsbookiebrawler/background-images/bloohunnuits_sports_betting_dystopian_at_sporting_event_blue_au_a5ff4ae1-646f-4381-92e5-1717953774a7.png",
  // ];

  // const randomIndex = Math.floor(Math.random() * backgroundImages.length);
  // const randomImage = backgroundImages[randomIndex];

  const handleLogin = () => {
    onLogin(true);
  };
  const handleLoginHome = () => {
    onLogin(true);
    navigate("/");
  };
  const handleLoginSugg = () => {
    onLogin(true);
    navigate("/suggestions");
    window.location.reload();
  };

  function handleNavigateToSuggestions() {
    navigate("/suggestions");
    window.location.reload();
  }

  const handleEarlySignup = () => {
    setShowWelcomeMessage(false);
    onLogin(true);
    setShowWelcomeMessage(true);
  };

  const handleLogout = () => {
    setShowWelcomeMessage(false);
    onLogout();
  };

  const loginHandler =
    redirectedFrom === "suggestions" ? handleLoginSugg : handleEarlySignup;
  //console.log(redirectedFrom, 'redirected', loginHandler);

  //uncomment to play music
  // useEffect(() => {
  //   const startAudio = () => toggle();
  //   window.addEventLGistener("click", startAudio);
  //   return () => {
  //     window.removeEventListener("click", startAudio);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     setShowWelcomeMessage(true);
  //   }
  // }, [isAuthenticated]);

  // useEffect(() => {

  //   //fetchCurrentUser();
  // }, []);

  // useEffect(() => {
  //   if (isAuthenticated && location.state?.redirected) {
  //     loginHandler();
  //   }
  // }, [isAuthenticated]);

  // useEffect(() => {
  //   const fetchCurrentUser = async () => {
  //     setShowWelcomeMessage(false);
  //     // console.log(shouldFetchUser,"ISa");
  //     if (await getIsAuthenticated()) {
  //       const response = await getCurrentUser();
  //       if (response && response.data) {
  //         setCurrentUser(response.data);
  //         setShowWelcomeMessage(true);
  //       }
  //     }
  //   };

  //   fetchCurrentUser();
  // }, [user]);

  useEffect(() => {
    const fetchUserRank = async (currentUser: User) => {
      if (currentUser) {
        const response = await getRequest(
          "api/v1/users/" + currentUser.id + "/rank"
        );
        setUserRank(response.data[0]);
        setTotalUsers(response.data[1]);
      }
    };
    if (finalUser.id !== 0) {
      fetchUserRank(finalUser);
    }
  }, [currentUser]);

  const welcomeMessage = finalUser.firstname && (
    <div>
      {finalUser.picture && (
        <img
          style={{
            height: "65px",
            width: "65px",
            borderRadius: "50%",
            border: "1.5px solid #ccc",
          }}
          src={finalUser.picture}
          alt="Profile"
        />
      )}
      <div className={styles.badge}>
        Brawler{" "}
        {userRank !== "0" && (
          <span className={`${styles.rank}`}>{"#" + userRank}</span>
        )}{" "}
        out of <span className={styles.total}>{totalUsers}</span>
      </div>

      <h6 className="text-powder-bloo guide-font pt-3">
        Hey {finalUser.firstname}!
      </h6>

      <div className="bg-powder-blue text-light-bloo rounded py-1 mb-2">
        <p>You've beat the buzzer and made the cut!</p>
        <p>
          Welcome to BookieBrawler, where early birds not only get the worm, but
          also an upgrade!
        </p>
        <p>
          Enjoy your leap from Bronze to Silver, or Silver to Gold, just for
          being an early adopter.
        </p>
        <p>Keep an eye on your inbox; we're just about to blow the whistle!</p>
        {/* <p>In the mean time, we would greatly appreciate your feedback on our suggestion wall. </p> */}
      </div>
    </div>
  );

  const shouldShowSignOut = showWelcomeMessage && finalUser.firstname !== "";
  return (
    <>
      <Helmet>
        <title>Login - BookieBrawler</title>
        <link rel="canonical" href="https://bookiebrawler.com/login" />
        <meta
          name="description"
          content="Log in to access BookieBrawler, your go-to platform for Positive EV betting strategies and tools. Enhance your betting skills and maximize your expected value with our comprehensive betting tools."
        />
        <meta name="robots" content="index,follow"></meta>
      </Helmet>

      <div
        className={styles.transparentWrapper+ " "} //removed .dark-bg for playful look 
        style={{ }}
      >
        <div
          className={`${styles.modalWrapper} d-flex justify-content-center align-items-center flex-column slate-dark-transparent light-brder rounded-lg shadow-lg `}
        >
          <div className="d-flex align-items-center justify-content-center mb-4">
            <img
              src={
                "https://storage.googleapis.com/assestsbookiebrawler/logos/Untitled%20design%20(4).png"
              }
              className={styles.logo + " mr-4"}
            />
            <h4 className="text-center text-powder-bloo retro ml-4">
              BookieBrawler
            </h4>
          </div>
          <div>
            {" "}
            <h4 className={styles.shine}>Sign Up</h4>
          </div>
          <div className={styles.modalHeading}>
            {showWelcomeMessage ? welcomeMessage : <></>}
            {/* {!shouldShowSignOut && (
            <GoogleButtonLogin
              onLogin={loginHandler}
              showOneTap={!isAuthenticated}
            />
          )} */}
            {!showWelcomeMessage && (
              <div>
                <button
                  className="border border-white transparent-background py-1 ml-autodark-transparent rounded m-2 px-3"
                  onClick={toggleModal}
                >
                  Login
                </button>
              </div>
            )}

            <div className={styles.buttonContainer}>
              {shouldShowSignOut && (
                <>
                  <button
                    className="border border-white transparent-background py-1 dark-transparent"
                    onClick={handleLogout}
                  >
                    Sign Out
                  </button>
                  <button
                    className="border border-white transparent-background py-1 ml-auto dark-transparent"
                    onClick={handleNavigateToSuggestions}
                  >
                    Suggestions
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
