import SocialIconList from "../../../components/commons/social-icons/SocialIconList";
import React from "react";
import { calculators } from "../../../components/content/betting-calculators/betting-calculators-data";
import { educationPosts } from "../../../components/content/betting-education/education-data";
import { blogPosts } from "../../../components/content/blog/blog-data";

// SEO-friendly link generator
const createSeoFriendlyLink = (text: string, href: string): JSX.Element => {
  return (
    <a
      href={href}
      title={text.replace(/\s+/g, "-").toLowerCase()}
      className="text-grayish hover:underline mb-2"
    >
      {text}
    </a>
  );
};

const Directory: React.FC = () => {
  return (
    <div className="grid grid-cols-2  md:grid-cols-3 lg:grid-cols-5 gap-8 pb-8 pt-5 main-padding">
      <div className="flex flex-col">
       <div className="mb-3"><img
          className="logo min-w-[160px] h-auto"
          src="https://storage.googleapis.com/assestsbookiebrawler/logos/brawler-text-logo-transparent-final.svg"
          alt="BookieBrawler"
        /></div>
        <SocialIconList/>
       
      </div>
      <div className="flex flex-col">
        <h3 className="text-powder-bloo text-[16px] font-semibold uppercase tracking-wider mb-4">Betting Tools</h3>
        {/* {createSeoFriendlyLink("Arbitrage Betting Tool", "#")}
        {createSeoFriendlyLink("Boosts Betting Tool", "#")}
        {createSeoFriendlyLink("Promo Conversion Tool", "#")}
        {createSeoFriendlyLink("Low Hold Betting Tool", "#")}
        {createSeoFriendlyLink("Middles Betting Tool", "#")}
        {createSeoFriendlyLink("Parlay Builder", "#")} */}
        {createSeoFriendlyLink("Positive EV Betting Tool", "/positive-ev")}
        {createSeoFriendlyLink("Arbitrage Betting Tool", "/arbitrage")}
      </div>
      <div className="flex flex-col">
        <h3 className="text-powder-bloo  text-[16px] font-semibold uppercase tracking-wider mb-4">Calculators</h3>
        {calculators.map((calculator) =>
          createSeoFriendlyLink(calculator.shortTitle, `/betting-calculators/${calculator.slug}`)
        )}
      </div>
      <div className="flex flex-col">

      <h3 className="text-powder-bloo  text-[16px] font-semibold uppercase tracking-wider mb-4">Betting Education</h3>
        {educationPosts.map((educationPost) =>
          createSeoFriendlyLink(educationPost.title, `/betting-education/${educationPost.slug}`)
        )}
        {/* <h3 className="text-gray-250 text-regular font-semibold uppercase tracking-wider mb-4">Betting Education</h3>
        {createSeoFriendlyLink("What is Arbitrage Betting?", "#")}
        {createSeoFriendlyLink("What is Positive EV in Sports Betting?", "#")}
        {createSeoFriendlyLink("What is a Moneyline?", "#")}
        {createSeoFriendlyLink("What is a Point Spread?", "#")}
        {createSeoFriendlyLink("What is an Over Under?", "#")}
        {createSeoFriendlyLink("What is Juice?", "#")}
        {createSeoFriendlyLink("What is Closing Line Value?", "#")}
        {createSeoFriendlyLink("What is an Odds Boost?", "#")}
        {createSeoFriendlyLink("What is a Deposit Bonus?", "#")}
        {createSeoFriendlyLink("What is a Signup Bonus Bet?", "#")}
        {createSeoFriendlyLink("What is a Bonus Bet?", "#")}
        {createSeoFriendlyLink("What is a Site Credit?", "#")} */}
      </div>
      <div className="flex flex-col">
        <h3 className="text-gray-350 text-powder-bloo  text-[16px] font-semibold uppercase tracking-wider mb-4">Brawler Blog</h3>
        {blogPosts.map((blogPost) =>
          createSeoFriendlyLink(blogPost.title, `/blog/${blogPost.slug}`)
        )}
      </div>
      {/* <div className="flex flex-col">
        {createSeoFriendlyLink("Getting Started Guide", "#")}
        {createSeoFriendlyLink("Bonus Bets", "#")}
        {createSeoFriendlyLink("Sportsbook Promo Codes", "#")}
        {createSeoFriendlyLink("Betting Education", "#")}
        {createSeoFriendlyLink("FAQ", "#")}
        {createSeoFriendlyLink("API", "#")}
        {createSeoFriendlyLink("Site Status", "#")}
        {createSeoFriendlyLink("Privacy", "#")}
        {createSeoFriendlyLink("Terms", "#")}
        {createSeoFriendlyLink("About Us", "#")}
        {createSeoFriendlyLink("Careers", "#")}
        {createSeoFriendlyLink("Testimonials", "#")}
        {createSeoFriendlyLink("Press", "#")}
        {createSeoFriendlyLink("Help Center", "#")}
      </div> */}
    </div>
  );
};

export default Directory;
