// blog-data.ts
import {
  createATag,
  createFormattedDateParagraph,
  createHyperlink,
} from "../../../services/common-util-service";
import { bookieSignUpLinks } from "../../../components/products/common/bookie-image";
import { ContentImage, ContentPost } from "../content-post/content-post-model";
import ArbitrageBettingWriteUp from "./arbitrage-write-up";
import PointSpreadInfo from "./point-spread-write-up";
import Quad1WinInfo from "./quad-win-writeup";
import ClosingLineValueInfo from "./clv-writeup";
import UnderdogBettingInfo from "./underdog-writeup";

export const runPoolImage: ContentImage = {
  id: 0,
  title: "13 run pool photo Aaron Judge ",
  source: "True Blue LA",
  imageUrl:
 "https://cdn.vox-cdn.com/thumbor/9o1wQ-GubJRQ8zmAVKba8-MDdkc=/0x0:6000x4000/1200x800/filters:focal(2219x440:3179x1400)/cdn.vox-cdn.com/uploads/chorus_image/image/73595905/2171957156.0.jpg",
};

export const createSeoFriendlyLink = (text: string, href: string): JSX.Element => {
  return (
    <a
      href={href}
      title={text.replace(/\s+/g, "-").toLowerCase()}
      className="text-grayish hover:underline mb-2"
    >
      {text}
    </a>
  );
};

export const chalkImage: ContentImage = {
  id: 1,
  title: "Chalk Betting horse racing photo",
  source: "AP Photos",
  imageUrl:
    "https://oddsjam.com/_next/image?url=https%3A%2F%2Fwp.oddsjam.com%2Fwp-content%2Fuploads%2F2022%2F02%2FAP22032010597151.jpg&w=3840&q=75",
};
// New image objects
export const positiveEVImage: ContentImage = {
  id: 2,
  title: "What is Positive EV Betting",
  source: "BookieBrawler",
  imageUrl:
    "https://storage.googleapis.com/assestsbookiebrawler/images/what-is-ev-betting-cover.png",
};

export const arbImage: ContentImage = {
  id: 2,
  title: "What is Positive EV Betting",
  source: "BookieBrawler",
  imageUrl:
    "https://storage.googleapis.com/assestsbookiebrawler/images/arb-infographic.svg",
};

export const spreadBettingImage: ContentImage = {
  id: 2,
  title: "What is Spread Betting",
  source: "USA Today",
  imageUrl:
    "https://www.usatoday.com/gcdn/authoring/authoring-images/2024/06/05/USAT/73986279007-2-doncictatum.jpg?crop=5273,2967,x7,y113&width=660&height=371&format=pjpg&auto=webp",
};

export const evOnboardImage: ContentImage = {
  id: 2,
  title: "Using the Postive EV Tool",
  source: "BookieBrawler",
  imageUrl:
    "https://storage.googleapis.com/assestsbookiebrawler/images/how-to-use-cover.png",
};

export const fliffImage: ContentImage = {
  id: 3,
  title: "How to Make Money Using Fliff Social Sportsbook",
  source: "BookieBrawler",
  imageUrl:
    "https://storage.googleapis.com/assestsbookiebrawler/images/how-to-make-money-on-fliff.png",
};

export const marchMadnessImage: ContentImage = {
  id: 3,
  title: "March Madness",
  source: "Gene J. Puskar / Associated Press",
  imageUrl:
    "https://ca-times.brightspotcdn.com/dims4/default/f397bb4/2147483647/strip/true/crop/5997x4000+0+0/resize/1200x800!/format/webp/quality/75/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2F1f%2Fb3%2Febfbd5d44bbebc0e5af9a288693f%2Fncaa-tournament-march-madness-basketball-21171.jpg",
};

export const clvImage: ContentImage = {
  id: 3,
  title: "",
  source: "Baishampayan Ghose",
  imageUrl:
    "https://upload.wikimedia.org/wikipedia/commons/e/e5/Las_Vegas_sportsbook.jpg",
};

export const underdogImage: ContentImage = {
  id: 3,
  title: "",
  source: "The Dematha Stagline",
  imageUrl:
    "https://demathastagline.com/wp-content/uploads/2023/03/in-play-betting-tips-sports-betting.jpg",
};


export const educationPosts: ContentPost[] = [
  {
    id: 6,
    title: "How to Utilize the BookieBrawler Positive EV Tool",
    slug: "how-to-use-the-bookiebrawler-positive-ev-tool",
    summary:
      "Discover how to effectively use the BookieBrawler Positive Expected Value Tool to place profitable sports bets.",
    content: (
      <>
        <p className="py-4 text-powder-bloo">
          Positive expected value (EV) betting represents a profitable strategy
          that we recommend for all our readers. If you're new to the concept of
          positive EV betting, you can deepen your understanding through the
          detailed explanation available{" "}
          {createSeoFriendlyLink(
            "here",
            "positive-expected-value-sports-betting"
          )}
          .
        </p>
        <p className="py-4 text-powder-bloo">
          This guide will specifically walk you through the steps to effectively
          utilize the{" "}
          {createSeoFriendlyLink(
            "BookieBrawler Positive Expected Value Tool",
            "/positive-ev"
          )}
          .
        </p>

        <h3 className="py-4 text-powder-bloo">
          The BookieBrawler Positive EV Filters
        </h3>
        <p className="py-4 text-white text-opacity-75">
          The first step when using the positive EV tool is to ensure your
          filters are set correctly. This tool allows extensive filtering to
          suit your specific betting needs.
        </p>
        <p className="py-4 text-white text-opacity-75">
          In the sportsbooks section, you can filter in or out books based on
          your preferences. Interested in NBA bets? Simply set the league filter
          to NBA, and the tool will display only bets for that league. You can
          also differentiate between odd ranges and ev percentage. This allows
          you to quickly develop and refine your personal strategy.
        </p>
        <p className="py-4 text-white text-opacity-75">
          Additionally, a standout feature of this tool is the ability to save
          your filter settings. If you frequently prefer certain settings—such
          as focusing on player props or main lines—you can save these
          preferences for quick setup in future sessions.
        </p>
        <p className="py-4 text-white text-opacity-75">
          Personally, I have filters saved for specific scenarios, such as when
          I’m concentrating on bets under +300 for the mlb
        </p>

        <h3 className="py-4 text-powder-bloo">
          How to Use BookieBrawler's Positive EV Betting Tool
        </h3>
        <p className="py-4 text-white text-opacity-75">
          With the filters set, let’s delve into how the tool itself functions.
        </p>
        <div className="flex justify-center">
          <img
            className="w-full h-auto max-w-[1000px] min-w-[285px] light-brder rounded-lg m-3"
            src="https://storage.googleapis.com/assestsbookiebrawler/images/how-to-use-ev.png"
            alt="What is Positive EV"
          />
        </div>
        <p className="py-4 text-white text-opacity-75">
          Here’s what a typical bet looks like in the positive EV tool: the bet
          you should focus on is the one that is bolded and highlighted with a
          blue outline. For example, in this scenario, the recommended bet is
          Noah Fifita Player Pass Touchdowns Under 1.5.
        </p>
        <p className="py-4 text-white text-opacity-75">
          While that’s the key highlight, here’s a breakdown of what each column
          represents, from left to right:
        </p>
        <ul className="list-disc px-8 py-4 text-white text-opacity-75">
          <li>
            <strong>Percent:</strong> This displays your Positive Expected Value
            percentage, or projected profit margin. For instance, an EV% of
            6.85% means you could expect a profit of $6.85 for every $100
            wagered.
          </li>
          <li>
            <strong>Rec. Bet Size:</strong> This column suggests the amount you
            should wager, calculated based on your bankroll using the Kelly
            Criterion Method.
          </li>
          <li>
            <strong>Date:</strong> Shows the time and date when the bet is
            available.
          </li>
          <li>
            <strong>Event:</strong> Indicates the specific game for the bet,
            like a play in the Arizona Wildcats vs. Kansas State Wildcats game
            in this example.
          </li>
          <li>
            <strong>Market:</strong> Describes the betting market, such as the
            Player Pass Touchdowns here.
          </li>
          <li>
            <strong>Bets:</strong> Details the specific bet to place, which will
            always be bolded and outlined as noted earlier.
          </li>
          <li>
            <strong>Books:</strong> Names the sportsbook where you should place
            the bet.
          </li>
          <li>
            <strong>No-Vig Odds (Fair Odds):</strong> Shows the “true line” for the market
            as calculated by BookieBrawler, indicating what the odds should
            realistically be.
          </li>
          {/* <li>
            <strong>Market Width:</strong> Measures how much juice or vig the
            sportsbooks are charging. A lower market width indicates less juice
            being added to the bet.
          </li> */}
        </ul>
        <p className="py-4 text-white text-opacity-75">
          While there’s a lot to absorb, the most crucial detail to remember is
          the highlighted bet—the bolded and outlined one—as this is your
          mathematically advantageous wager.
        </p>

        <h3 className="py-4 text-powder-bloo">
          Should I Bet Every Play on the Positive EV Page?
        </h3>
        <p className="py-4 text-white text-opacity-75">
          Great question! I had a feeling you might ask that. While every bet
          listed on the BookieBrawler EV page is mathematically profitable, we
          still recommend conducting further research before placing any bets.
          This is a practice I personally follow for each bet I consider.
        </p>
        <p className="py-4 text-white text-opacity-75">
          For detailed odds from every sportsbook on a specific bet, simply
          click the blue info icon located to the right of the bet. This will
          provide you with all available betting options for that event.
        </p>

        <img
          className="w-full h-auto max-w-[1000px] min-w-[285px] light-brder rounded-lg m-3"
          src=" https://storage.googleapis.com/assestsbookiebrawler/images/market-view-new.png"
          alt="What is Positive EV"
        />

        <p className="py-4 text-white text-opacity-75">
          This action allows you to compare the odds of the bet you’re
          considering (e.g., under 1.5 at +165 odds) with those offered by other
          sportsbooks. The objective is to determine if the odds you’re getting
          are an outlier compared to the broader market. If they are, that’s
          your cue to place the bet. For instance, if our bet is at +165 while
          most markets are at +135 or +130, it clearly stands out as a
          profitable opportunity.
        </p>
        <p className="py-4 text-white text-opacity-75">
          Stay tuned for more insights into the value of positive expected value
          betting. If you have any questions, don’t hesitate to contact us at{" "}
          {createSeoFriendlyLink(
            "notifications@bookiebrawler.com",
            "mailto:notifications@bookiebrawler.com"
          )}
        </p>
      </>
    ),
    author: "BookieBrawler Team",
    publishedDate: new Date(),
    image: evOnboardImage,
    coverPhoto:
      "https://storage.googleapis.com/assestsbookiebrawler/images/how-to-use-cover.png",
  },
  {
    id: 4,
    title: "What is a 13 Run Pool for MLB? MLB 13 Run Rules",
    slug: "mlb-13-run-pool-rules",
    summary: "Understand the rules and how to run a 13 run pool for MLB.",
    content: (
      <div>
        <h2 className="text-powder-bloo mt-5 mb-4">
          What is a 13 Run Pool for MLB?
        </h2>
        <p className="my-2">
          A 13 Run Pool is an engaging way to enjoy the baseball season with
          friends or colleagues. Participants each put in a set amount of money
          into a pool and are assigned a Major League Baseball team. Some
          variations of the game involve switching teams each week, while others
          keep the same team throughout the season. Another popular format is
          similar to a survival pool, where you select a different team each
          week but can only use each team once all season.
        </p>
        <p className="my-2">
          The goal is to have your team score every run total from 0 to 13 in
          different games over the season. For example, if your team, the
          Philadelphia Phillies, scores 3, 5, 0, 8, 7, 2, and 5 in a week, you
          can cross off 0, 2, 3, 5, 7, and 8. You will still need to get exactly
          1, 4, 6, 9, 10, 11, 12, and 13 at some point during the season to win.
        </p>

        <h2 className="text-powder-bloo mt-5 mb-4">How to Run a 13 Run Pool</h2>
        <p className="my-2">
          To organize a 13 run pool, gather interested participants and explain
          the rules. Collect the entry fee from each person and keep a master
          list of the run totals each participant has achieved to avoid
          disputes. Adjust the rules as needed to suit your group.
        </p>
        <h2 className="text-powder-bloo mt-5 mb-4">MLB 13 Run Pool Rules</h2>
        <p className="my-2">
          Regardless of how teams are assigned or rotated, each participant's
          team must score exactly 0 through 13 runs in different games
          throughout the regular season. The first participant to complete this
          wins the pool.
        </p>
        <h2 className="text-powder-bloo mt-5 mb-4">
          Tiebreakers in a 13 Run Pool
        </h2>
        <p className="my-2">
          If a tie occurs (no one completes all 13 runs or multiple people do so
          on the same day), tiebreakers can include total runs scored by the
          teams all season, the number of shutouts, fewer games played, or the
          earliest to score 13 runs.
        </p>

        <h2 className="text-powder-bloo mt-5 mb-4">Pros of a 13 Run Pool</h2>
        <ul>
          <li>
            Simplicity: Focus on your team's run totals without daily
            management.
          </li>
          <li>
            <strong>Excitement</strong>: Every game brings a chance to progress.
          </li>
          <li>
            <strong>Community</strong>: Engage in friendly competition with
            peers.
          </li>
          <li>
            <strong>Unique Fun</strong>: A creative twist on traditional
            betting.
          </li>
        </ul>

        <h2 className="text-powder-bloo mt-5 mb-4">Cons of a 13 Run Pool</h2>
        <ul>
          <li>
            <strong>Randomness</strong>: Team performance can be unpredictable.
          </li>
          <li>
            <strong>Possible Monotony</strong>: Waiting for rare scores like 12
            or 13 can lessen daily excitement towards the season's end.
          </li>
        </ul>
      </div>
    ),
    author: "BookieBrawler Team",
    publishedDate: new Date(),
    image: runPoolImage,
    //date: new Date(2024, 6, 12, 15, 2),
    coverPhoto:
      "https://cdn.vox-cdn.com/thumbor/9o1wQ-GubJRQ8zmAVKba8-MDdkc=/0x0:6000x4000/1200x800/filters:focal(2219x440:3179x1400)/cdn.vox-cdn.com/uploads/chorus_image/image/73595905/2171957156.0.jpg",
  },
  {
    id: 5,
    title: "What is Chalk in Sports Betting?",
    slug: "chalk",
    summary:
      "Learn what 'chalk' means in sports betting and how it affects your betting strategy.",
    content: (
      <div>
        <h2 className="text-powder-bloo mt-5 mb-4">
          Who or What is Chalk in Sports Betting?
        </h2>
        <p className="my-2">
          The term "chalk" is commonly used during March Madness when people
          fill out brackets. A "chalk" bracket has few to no upsets and features
          top-seeded teams in the Final Four, which is rare. In general sports
          betting, "chalk" refers to heavy favorites. While an underdog is never
          chalk, not all favorites are chalk. A favorite with close odds to the
          opponent is not considered chalk.
        </p>
        {/* <div className="flex justify-center">
          <img
            className="w-full h-auto max-w-[1000px] min-w-[285px] rounded-lg m-5"
            src="https://oddsjam.com/_next/image?url=https%3A%2F%2Fwp.oddsjam.com%2Fwp-content%2Fuploads%2F2022%2F02%2FAP22032010597151.jpg&w=3840&q=75"
            alt="Chalk betting example"
          />
        </div> */}

        <h2 className="text-powder-bloo mt-5 mb-4">
          Why is it Called “Chalk”?
        </h2>
        <p className="my-2">
          The term "chalk" originates from horse racing when odds were written
          on chalkboards. Favorite horses' names would be covered in chalk dust
          due to frequent updates. Another theory suggests bookmakers wrote only
          the favorite's line on the board, leading to more chalk use for
          favorites.
        </p>

        <h2 className="text-powder-bloo mt-5 mb-4">Examples of Chalk</h2>
        <p className="my-2">
          Let's look at some Week 17 games from the 2021-22 NFL season:
        </p>
        <ul>
          <li>
            <strong>PHI (-5.5) @ WAS:</strong> Too close to be considered chalk.
          </li>
          <li>
            <strong>JAX @ NE (-15.5):</strong> Definite chalk. The Patriots won
            big.
          </li>
          <li>
            <strong>NYG @ CHI (-6.5):</strong> Borderline chalk due to teams'
            standings.
          </li>
          <li>
            <strong>ATL @ BUF (-14.5):</strong> Chalk pick. Bills won by 14
            points.
          </li>
          <li>
            <strong>TB (-14.5) @ NYJ:</strong> Chalk pick. Bucs won narrowly.
          </li>
          <li>
            <strong>HOU @ SF (-13):</strong> Chalk pick. 49ers won 23-7.
          </li>
          <li>
            <strong>ARI @ DAL (-6):</strong> Chalk pick. Cowboys lost 22-25.
          </li>
          <li>
            <strong>MIN @ GB (-12.5):</strong> Chalk pick. Packers won 37-10.
          </li>
        </ul>
        <p className="my-2">
          Of these games, the Patriots, 49ers, and Packers covered the spread,
          while the Bills, Bucs, and Cowboys did not. Many casual bettors parlay
          chalk picks, assuming favorites will win outright.
        </p>

        <h2 className="text-powder-bloo mt-5 mb-4">Pros of Betting Chalk</h2>
        <ul>
          <li>
            <strong>Simplicity</strong>: Easy to follow favorites.
          </li>
          <li>Confidence: Favorites are usually safer bets.</li>
        </ul>

        <h2 className="text-powder-blo mt-5 mb-4">Cons of Betting Chalk</h2>
        <ul>
          <li>
            <strong>Lower Payouts</strong>: Favorites offer smaller returns.
          </li>
          <li>Unpredictability: Upsets can ruin bets.</li>
        </ul>
      </div>
    ),
    author: "BookieBrawler Team",
    publishedDate: new Date(),
    image: chalkImage,
    coverPhoto:
      "https://oddsjam.com/_next/image?url=https%3A%2F%2Fwp.oddsjam.com%2Fwp-content%2Fuploads%2F2022%2F02%2FAP22032010597151.jpg&w=3840&q=75",
  },
  {
    id: 5,
    title:
      "What is Positive Expected Value in Sports Betting? How to Calculate Expected Value in Betting",
    slug: "positive-expected-value-sports-betting",
    summary:
      "Learn the concept of Positive Expected Value in sports betting and how to calculate it to make profitable bets.",
    content: (
      <>
        {/* <p className="py-1 text-white text-opacity-75">
          BookieBrawler Team July 11, 2024 at 7:35 PM
        </p>
        <div className="flex justify-center">
          <img
            className="w-full h-auto max-w-[1000px] min-w-[285px] light-brder rounded-lg m-3"
            src="https://storage.googleapis.com/assestsbookiebrawler/images/what-is-ev-betting-cover.png"
            alt="What is Positive EV Betting"
          />
        </div> */}

        <p className="py-4 text-powder-bloo">
          Many bettors have probably heard of the term "expected value," but not
          all of them understand what it really means, and even fewer actually
          apply it to their bets.
        </p>
        <h3 className="py-4 text-powder-bloo">What is Expected Value?</h3>
        <p className="py-4 text-white text-opacity-75">
          Expected value is the predicted value of a variable. To calculate it,
          you sum all possible values and then multiply each by the probability
          of its occurrence. This concept isn't just for sports betting—it can
          be applied to investments or any scenario involving probabilities.
        </p>
        <p className="py-4 text-white text-opacity-75">
          In sports betting, expected value measures the probability gap between
          a bettor's expectations of an event's outcome and the sportsbook's
          expectations for the same event. It quantifies this gap as a
          percentage, helping bettors identify positive expected value bets,
          which are mathematically profitable. The higher the percentage, the
          better the profit margin.
        </p>
        <p className="py-4 text-white text-opacity-75">
          This might sound confusing, so let's break it down in the context of
          Positive Expected Value betting.
        </p>
        <h3 className="py-4 text-powder-bloo">What is Positive EV Betting?</h3>
        <p className="py-4 text-white text-opacity-75">
          Positive EV betting means placing bets where there is a positive
          probability gap, or Positive Expected Value, between what the bettor
          expects and what the sportsbook expects for an event's outcome. Let's
          see how BookieBrawler's positive ev bet finder can scope out the few
          of thousands of sports bets with Positve EV (+EV).
        </p>
        <div className="flex justify-center">
          <img
            className="w-full h-auto max-w-[1000px] min-w-[285px] light-brder rounded-lg m-3"
            src="https://storage.googleapis.com/assestsbookiebrawler/images/what-is-positive-ev.png"
            alt="What is Positive EV"
          />
        </div>
        {/* <h3 className="py-4 text-powder-bloo">
          What is Positive Expected Value in Sports Betting? How to Calculate
          Expected Value in Betting
        </h3> */}
        <p className="py-4 text-white text-opacity-75">
          The screenshot above is the bread and butter of how you can use
          BookieBrawler to become a sharp sports bettor. In the example shown,
          the BookieBrawler Perfect Line prices Shohei Ohtani's Over 0.5 Home
          Runs in the Los Angeles Dodgers vs. Philadelphia Phillies game at
          +176. Using the BookieBrawler line shopping tool and Positive EV
          betting tool, we found this bet at +196 on FanDuel. This is a Positive
          EV play because we are getting better odds than what the "true" line
          is calculated to be from the BookieBrawler Perfect Line.
        </p>
        {/* <p className="py-4 text-white text-opacity-75">
          This approach is the bread and butter of how you can use BookieBrawler
          to become a sharp sports bettor.
        </p> */}
        <h3 className="py-4 text-powder-bloo">
          Why is Expected Value Important to Bettors?
        </h3>
        <p className="py-4 text-white text-opacity-75">
          Now that you know what Expected Value is, and more importantly,
          Positive Expected Value, let’s delve into why it is so crucial for
          bettors.
        </p>
        <p className="py-4 text-white text-opacity-75">
          The easiest way to explain this is with a coin flip and probabilities.
        </p>
        <p className="py-4 text-white text-opacity-75">
          In sports betting, when a sportsbook deems a bet a 50/50 proposition,
          the odds are typically priced at -110 on each side. This reflects the
          vig (or commission) that sportsbooks charge. So, in a coin flip
          example, both heads and tails would be priced at -110 odds, which
          implies a probability of 52.38% for each.
        </p>
        <p className="py-4 text-white text-opacity-75">
          Let's say you flipped the coin 100 times and bet $100 on heads every
          time at -110 odds ($100 bet wins $90). Statistically, the results
          would be heads 50 times and tails 50 times. So, for every heads
          result, you would profit a total of $4,500 (50 * $90), and for every
          tails result, you would lose a total of $5,000 (50 * $100), leaving
          you with a net loss of $500. This illustrates a negative expected
          value bet.
        </p>
        <p className="py-4 text-white text-opacity-75">
          Now, let's say we discovered that we are using a weighted coin, and
          heads actually has a 60% chance and tails a 40% chance. In this case,
          for 100 coin flips, you would profit a total of $5,400 on heads (60 *
          $90) and lose $4,000 on tails (40 * $100), resulting in a profit of
          $1,400. Even though one flip might land on tails, you know that over
          time you will make money with this strategy. This is positive expected
          value betting.
        </p>
        <p className="py-4 text-white text-opacity-75">
          As a sports bettor, determining the expected value of a bet is crucial
          because it distinguishes between profiting over time and losing money
          in the long run.
        </p>
        <h3 className="py-4 text-powder-bloo">
          How do I Calculate Expected Value in Sports Betting?
        </h3>
        <p className="py-4 text-white text-opacity-75">
          While BookieBrawler has an expected value calculator to do the heavy
          lifting for you, it's helpful to understand the math behind it. Here's
          the formula:
        </p>
        <p className="py-4 text-white text-opacity-75">
          Expected Value = (Winning implied probability % * profit if bet won) –
          (Losing implied probability % * stake)
        </p>
        <p className="py-4 text-white text-opacity-75">
          If the result is positive, it means the bet has a positive expected
          value, indicating that if you simulated the event an infinite number
          of times, you would always net a profit.
        </p>
        <h3 className="py-4 text-powder-bloo">Example with a 50% Coin Flip</h3>
        <p className="py-4 text-white text-opacity-75">
          Let's revisit the 50% coin flip scenario to see how negative EV looks:
        </p>
        <p className="py-4 text-white text-opacity-75">
          (50% * $90) – (50% * $100) = -5%
        </p>
        <p className="py-4 text-white text-opacity-75">
          This means the bet has a negative expected value of 5%, or a -5%
          expected profit margin.
        </p>
        <h3 className="py-4 text-powder-bloo">Example with a Weighted Coin</h3>
        <p className="py-4 text-white text-opacity-75">
          Now, let’s see how it looks with the weighted coin example:
        </p>
        <p className="py-4 text-white text-opacity-75">
          (60% * $90) – (40% * $100) = 14%
        </p>
        <p className="py-4 text-white text-opacity-75">
          This results in a positive expected value of 14%, or a 14% expected
          profit margin.
        </p>
        <h3 className="py-4 text-powder-bloo">Vig and Expected Value</h3>
        <p className="py-4 text-white text-opacity-75">
          The vig (or juice) plays a crucial role in expected value. Sportsbooks
          charge a vig on every bet, so when you calculate the implied
          probability associated with those odds, it's important to do so with
          the vig removed. These are called the “fair odds” or “no-vig odds.”
        </p>
        <p className="py-4 text-white text-opacity-75">
          For the coin flip, despite getting -110 odds, the true percentage was
          50/50 each way. The implied probability of -110 odds is 52.38%, and
          adding both probabilities gives you 104.76%. That extra 4.76% is the
          juice. Removing that makes the “fair odds” +100, implying a 50%
          probability on either side.
        </p>
        {createATag("betting-calculators/expected-value", "EV Calculator")}
        {createATag("positive-ev", "Positive EV Betting Tool")}
      </>
    ),
    author: "BookieBrawler Team",
    publishedDate: new Date(),
    image: positiveEVImage,
    coverPhoto:
      "https://storage.googleapis.com/assestsbookiebrawler/images/what-is-ev-betting-cover.png",
  },
  {
    id: 6,
    title: "How to Make Money Using Fliff Social Sportsbook",
    slug: "how-to-make-money-using-fliff-social-sportsbook",
    summary:
      "Learn how to make money using Fliff Social Sportsbook with this comprehensive guide on Fliff Cash, Fliff Coins, and finding value in your bets.",
    content: (
      <>
        {/* <p className="py-1 text-white text-opacity-75">
          BookieBrawler Team July 13, 2024 at 1:10 AM
        </p>

        <div className="flex justify-center">
          <img
            className="w-full h-auto max-w-[1000px] min-w-[285px] light-brder rounded-lg m-5"
            src="https://storage.googleapis.com/assestsbookiebrawler/images/how-to-make-money-on-fliff.png"
            alt="Example of run pool tracking"
          />
        </div> */}

        <p className="py-4 text-powder-bloo">
          Want to get started with Fliff Social Sportsbook? Here’s a complete
          rundown on how to make money using Fliff. First of all, you can sign
          up for Fliff using this{" "}
          {createHyperlink("link", bookieSignUpLinks["fliff"])} for a
          first-purchase match up to $100.
        </p>
        <h3 className="py-4 text-powder-bloo">Making Money With Fliff</h3>
        <p className="py-4 text-white text-opacity-75">
          Fliff is a social sportsbook available in most states, functioning
          similarly to traditional sportsbooks but with notable legal
          differences. Users only need to be 18 years old to join and use the
          platform, as it is classified as a Social Sportsbook. This excuses
          Fliff from some strict regulations that apply to other sportsbooks
          like DraftKings or FanDuel.
        </p>

        <h3 className="py-4 text-powder-bloo">
          How do Fliff Cash & Fliff Coins Work?
        </h3>
        <p className="py-4 text-white text-opacity-75">
          Their currency is also different from traditional sportsbooks. Fliff
          has “Fliff Coins,” which are virtual currency and not real dollars.
          More importantly to most sports bettors, they also have free “Fliff
          Cash,” which converts to dollars at a 1-to-1 ratio. When you make a
          $50 purchase on Fliff, you’re sometimes awarded 50 Fliff Cash for
          free. When you redeem, it’s the reverse where you turn Fliff Cash back
          into cash prizes.
        </p>
        <p className="py-4 text-white text-opacity-75">
          Fliff uses a different currency system than traditional sportsbooks.
          They have “Fliff Coins,” a virtual currency that isn’t real money.
          More importantly, they offer free “Fliff Cash,” which can be converted
          to dollars at a 1-to-1 ratio. For example, if you make a $50 purchase
          on Fliff, you might receive 50 Fliff Cash for free. When you redeem
          Fliff Cash, it turns back into cash prizes.
        </p>

        <h3 className="py-4 text-powder-bloo">How to Make Picks Using Fliff</h3>
        <p className="py-4 text-white text-opacity-75">
          Its simple, the same approach you would when using DraftKings,
          FanDuel, Caesars or any other sportsbook. BookieBrawler’s Positive
          Expected Value betting tool is your best friend for finding value at
          every book, and that includes Fliff. The positive ev betting tool
          scans the market in real-time and finds outlier lines where value
          exists.
        </p>
        {/* <p className="py-4 text-white text-opacity-75">
          Lets say Fliff is offering the Grizzlies -1.5 at +145 odds, while every other
          legal sportsbook is in the +110 to +124 range. The BookieBrawler
          Perfect Line has it at +130. That means if you bet $100 on FanDuel and
          it won, you would win $124. But if you make the pick on Fliff instead,
          you win $145. That extra $21 makes a huge difference over a large
          sample size. Obviously not every pick will win, but taking these +EV
          picks over a large sample size will make you profitable.
        </p> */}
        <p className="py-4 text-white text-opacity-75">
          Fliff is 100% worth getting, especially if you live in a state where
          legal sports betting is not permitted. Unlike daily fantasy apps like
          PrizePicks and Underdog (which also are valuable), Fliff has actual
          lines and odds and not just player props.
        </p>
      </>
    ),
    image: fliffImage,
    author: "BookieBrawler Team",
    publishedDate: new Date(),
    coverPhoto:
      "https://storage.googleapis.com/assestsbookiebrawler/images/how-to-make-money-on-fliff.png",
  },
  {
    id: 3,
    title: "What is Arbitrage Betting? How Does Arbitrage Betting Work?",
    slug: "arbitrage",
    summary: "FAQs about arbitrage betting and how to make risk-free money.",
    content: <ArbitrageBettingWriteUp />,
    author: "BookieBrawler Team",
    publishedDate: new Date(),
    coverPhoto: arbImage.imageUrl, // placeholder image
    image: arbImage,
  },
  {
    id: 9,
    title: "Spread Betting Explained – How Does it Work and What Does -4.5, -3.5, -7.5 Mean?",
    slug: "point-spread",
    summary: "How does bet spread work?",
    content: <PointSpreadInfo/>,
    author: "BookieBrawler Team",
    publishedDate: new Date(),
    coverPhoto: spreadBettingImage.imageUrl, // placeholder image
    image: spreadBettingImage,
  },
  {
    id: 10,
    title: "What is a Quad 1 Win in College Basketball? Explaining NCAAB Quadrants",
    slug: "what-is-a-quad-1-win-in-college-basketball",
    summary: "Bookiebrawler team explains quad 1 wins so you are prepared for March Madness",
    content: <Quad1WinInfo/>,
    author: "BookieBrawler Team",
    publishedDate: new Date(),
    coverPhoto: marchMadnessImage.imageUrl, // placeholder image
    image: marchMadnessImage,
  },
  {
    id: 11,
    title: "What is Closing Line Value in Sports Betting? How to Track Closing Line Value",
    slug: "closing-line-value",
    summary: "What is Closing Line Value in Sports Betting? How to Track Closing Line Value",
    content: <ClosingLineValueInfo/>,
    author: "BookieBrawler Team",
    publishedDate: new Date(),
    coverPhoto: clvImage.imageUrl, 
    image: clvImage,
  },
  {
    id: 11,
    title: "What is an Underdog in Sports Betting? What does Underdog Mean?",
    slug: "underdog",
    summary: "What is an Underdog in Sports Betting? What does Underdog Mean?",
    content: <UnderdogBettingInfo/>,
    author: "BookieBrawler Team",
    publishedDate: new Date(),
    coverPhoto: underdogImage.imageUrl, 
    image: underdogImage,
  },
];
