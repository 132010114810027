import axios, { AxiosResponse } from "axios";
import jwtDecode from "jwt-decode";
import { DecodedToken } from "../components/types/DecodedToken";
import { User } from "../components/types/User";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const accessTokenKey = "access_token";
const refreshTokenKey = "refresh_token";
const profilePicKey = "profile_pic";
const userRoleKey = "user_role";



export const getAccessToken = (): string | null =>
  localStorage.getItem(accessTokenKey);
export const setAccessTokenLocal = (token: string): void => {
  localStorage.setItem(accessTokenKey, token);
};
const getRefreshToken = (): string | null =>
  localStorage.getItem(refreshTokenKey);
export const setRefreshTokenLocal = (token: string): void =>
  localStorage.setItem(refreshTokenKey, token);

export const getProfilePic = (): string | null =>
  localStorage.getItem(profilePicKey);
export const setProfilePic = (url: string): void =>
  localStorage.setItem(profilePicKey, url);

export const getUserRole = (): string | null =>
  localStorage.getItem(userRoleKey);
export const setUserRole = (role: string): void =>
  localStorage.setItem(userRoleKey, role);

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
let requestInterceptor: number | null = null;

export const setAuthorizationHeader = (newAccessToken: string | null): void => {
  // Save the new access token in the local storage
  // setAccessToken(newAccessToken);

  // If there is an old interceptor, eject it
  if (requestInterceptor !== null) {
    apiClient.interceptors.request.eject(requestInterceptor);
  }

  // Set new interceptor with the new access token
  setInterceptorWithToken(newAccessToken);
};

export const setInterceptorWithToken = (accessToken: string | null) => {
  apiClient.interceptors.request.use(
    (config) => {
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error: any) => {
      return Promise.reject(error);
    }
  );
};

//setInterceptorWithToken(getAccessToken());

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(accessTokenKey);
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

export const setResponseInterceptorWithToken = (
  accessToken: string | null
): void => {
  apiClient.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    async (error: { config: any; response: { status: number } }) => {
      const originalRequest = error.config;
      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const isRefreshed = await refreshAccessToken();
        if (isRefreshed) {
          originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
          return apiClient(originalRequest);
        }
      }
      return Promise.reject(error);
    }
  );
};

//recreate in HandleTokens

//setResponseInterceptorWithToken(getAccessToken());

apiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: { config: any; response: { status: number } }) => {
    const originalRequest = error.config;
    console.log("Error response:", error.response); // Add this line to check the error.response object
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const isRefreshed = await refreshAccessToken();
      if (isRefreshed) {
        originalRequest.headers["Authorization"] =
          "Bearer " + localStorage.getItem(accessTokenKey);
        return apiClient(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export const apiCallToRefreshToken = async (refreshToken: string) => {
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${refreshToken}`,
  };

  const response = await fetch(BASE_URL + "/api/v1/auth/refresh-token", {
    method: "POST",
    headers,
  });

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error("Failed to refresh token");
  }
};

export const refreshAccessToken = async (): Promise<boolean> => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    return false;
  }

  const tokenData = await apiCallToRefreshToken(refreshToken);

  if (tokenData) {
    setAccessTokenLocal(tokenData.access_token);
    setRefreshTokenLocal(tokenData.refresh_tokens);
    return true;
  }

  return false;
};
//recreate in HandleTokens for full context usage

export const getRequest = async (url: string, useCache: boolean = true): Promise<any> => {
  try {
    const response = await apiClient.get(url, {
      headers: useCache ? {} : { 'Cache-Control': 'public, max-age=15, must-revalidate' }
    });
    return response;
  } catch (error) {
    console.error("Failed to fetch data", error);
    return null;
  }
};

export const postRequest = async (
  url: string,
  body: any,
  isMultipart = false
): Promise<any> => {
  try {
    const response = await apiClient.post(
      url,
      body,
      isMultipart
        ? {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        : {}
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data", error);
    return null;
  }
};

export const patchRequest = async (url: string, body: any): Promise<any> => {
  try {
    const response = await apiClient.patch(url, body);
    return response.data;
  } catch (error) {
    console.error("Failed to update data", error);
    return null;
  }
};

export const deleteRequest = async (url: string): Promise<any> => {
  try {
    const response = await apiClient.delete(url);
    return response.data;
  } catch (error) {
    console.error("Failed to delete data", error);
    return null;
  }
};

export const getCurrentUserPromise = async (): Promise<any> => {
  const url = "/api/v1/users/me";
  setAuthorizationHeader(await getAccessToken());
  const response = await getRequest(url);
  return response;
};






export const putRequest = async (url: string, body: any): Promise<any> => {
  try {
    const response = await apiClient.put(url, body);
    return response;
  } catch (error) {
    console.error("Failed to update data", error);
    return null;
  }
};

export function isTokenExpired(decodedToken: DecodedToken): boolean {
  const currentTime = Date.now() / 1000;
  return decodedToken.exp < currentTime;
}

export const getIsAuthenticated = async (): Promise<boolean> => {
  const accessToken = getAccessToken();

  if (!accessToken) {
    return false;
  }

  try {
    const decodedToken = jwtDecode<DecodedToken>(accessToken);

    if (isTokenExpired(decodedToken)) {
      const isRefreshed = await refreshAccessToken();
      if (!isRefreshed) {
        console.error("Token expired must re-authenticate");
        return false;
      }
    }

    return true;
  } catch (error) {
    console.error("Failed to decode access token:", error);
    return false;
  }
};


export const getCurrentUser = async (): Promise<any> => {
  const authed = await getIsAuthenticated();
  if (authed) return getCurrentUserPromise();
  return 
};

const apiCallToGoogleAuth = async (
  authToken: any
): Promise<{ user: User; tokens: any } | null> => {
  const authorizationToken = authToken;

  try {
    const response = await fetch(
      BASE_URL + "/api/v1/auth/authenticate/google",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ authorizationToken }),
      }
    );

    if (response.ok) {
      const authResponse = await response.json();
      console.log("Login successful");
      return { user: authResponse.user, tokens: authResponse };
    } else {
      console.error("Login failed");
      return null;
    }
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};

const apiCallToGoogleAuthFirebase = async (authToken: any) => {
  const authorizationToken = authToken;

  try {
    const response = await fetch(
      BASE_URL + "/api/v1/auth/authenticate/google/base",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ idToken: authorizationToken }),
      }
    );

    if (response.ok) {
      const authResponse = await response.json();
      return { user: authResponse.user, tokens: authResponse };
    } else {
      console.error("Login failed");
      return null;
    }
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};

export const handleGoogleSignInAuthTokens = async (
  authToken: any,
  useFirebase?: boolean
): Promise<{ user: User; tokens: any } | null> => {
  const response = useFirebase
    ? await apiCallToGoogleAuthFirebase(authToken)
    : await apiCallToGoogleAuth(authToken);

  if (response) {
    // Remove this when you no longer need to handle local storage for auth
    setAccessTokenLocal(response.tokens.access_token);
    setRefreshTokenLocal(response.tokens.refresh_token);
    setAuthorizationHeader(response.tokens.access_token);
    setProfilePic(response.user.picture);
    setUserRole(response.user.role)
    //console.log(response.user.role, "userrole::");
    return response;
  } else {
    return null;
  }
};

// export const handleGoogleAuthSuccess = async (
//   credentialResponse: any,

// ): Promise<{ user: User; tokens: any } | null> => {
//   const authorizationToken = credentialResponse.credential;

//   try {
//     const response = await fetch(
//       "http://localhost:8080/api/v1/auth/authenticate/google",
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ authorizationToken }),
//       }
//     );

//     if (response.ok) {
//       const authResponse = await response.json();

//       console.log("Login successful authRe", authResponse);
//       setAccessTokenLocal(authResponse.access_token);
//       setRefreshTokenLocal(authResponse.refresh_token);

//       return { user: authResponse.user, tokens: authResponse };
//     } else {
//       console.error("Login failed");
//       //setUser({} as User);  // unset the user in case of failure
//       return null;
//     }
//   } catch (error) {
//     console.error("Error:", error);
//     return null;
//   }
// };

export const logout = async (
  setIsUserAuthenticated?: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  try {
    const response = await apiClient.post("/api/v1/auth/logout");

    if (response.status === 200) {
      localStorage.removeItem(accessTokenKey);
      localStorage.removeItem(refreshTokenKey);
      localStorage.removeItem(profilePicKey);
      localStorage.removeItem(userRoleKey);
    //  setIsUserAuthenticated(false);
      console.log("You've been logged out successfully.");
    } else {
      console.error("Failed to log out on server side.");
    }
  } catch (error) {
    console.error("An error occurred during logout: ", error);
  }
};

// export async function getUserEmail(): Promise<string | null> {
//   const accessToken = getAccessToken();
//   if (!accessToken) {
//     return null;
//   }

//   const decodedToken = jwtDecode<DecodedToken>(accessToken);

//   if (isTokenExpired(decodedToken)) {
//     const isRefreshed = await refreshAccessToken();
//     if (!isRefreshed) {
//       return null;
//     }
//     const newAccessToken = getAccessToken();
//     if (!newAccessToken) {
//       return null;
//     }
//     const newDecodedToken = jwtDecode<DecodedToken>(newAccessToken);
//     return newDecodedToken.sub;
//   }

//   return decodedToken.sub;
// }
