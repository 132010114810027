import { setErrorMap } from "zod";
import { postRequest } from "../../../../../services/apiService";

export interface MarketViewDTO {
  odd_id: string;
  market_type: string;
  description: string;
  bet_type: string;
  event: string;
}



export const DEFAULT_ODDS: [string, number | undefined, number | undefined][] = [
  ["brawler",undefined , undefined ],
  ["brawler",undefined , undefined ],
  ["brawler",undefined , undefined ],
  ["brawler",undefined , undefined ],
  ["brawler",undefined , undefined ],
  ["brawler",undefined , undefined ],
  ["brawler",undefined , undefined ],
  ["brawler",undefined , undefined ],
  ["brawler",undefined , undefined ],
  // ["espnbet",undefined , undefined ],
  // ["fliff",undefined , undefined ],
  // ["betrivers",undefined , undefined ],
];

export const getMarketViewForRow = async (originalObject: any): Promise<any> => {
  const marketViewDTO = transformToMarketViewDTO(originalObject);
  const url = "/api/v1/market-view/retrieve";
  return await postRequest(url, marketViewDTO);
};

export const getMarketView = async (dto: any): Promise<any> => {
  const url = "/api/v1/market-view/retrieve";
  return await postRequest(url, dto);
};

const MAIN_MARKETS = ["moneyline", "point spread", "point total"];

function isMainMarket(market: string): boolean {
    return MAIN_MARKETS.includes(market.toLowerCase());
}


export const transformToMarketViewDTO = (originalObject: any): MarketViewDTO => {
  
  const betNameWithEv = originalObject.away_evpercent>= originalObject.home_evpercent? originalObject.away_bets[0].name : originalObject.home_bets[0].name;
  const awayBet = originalObject.away_bets[0];
  const isMainMkt = isMainMarket(originalObject.market);
  const description = isMainMkt? "" : parseBetName(awayBet.name).description;
  const betType = isMainMkt? betNameWithEv.trim() : parseBetName(awayBet.name).betType;
  

  console.log(originalObject,"originalObject",betType,"betType",betNameWithEv,"isMainMkt",isMainMkt);
  return {
    odd_id: originalObject.odd_id,
    market_type: originalObject.market,
    description,
    bet_type: betType,
    event: originalObject.event,
  };
};



//for playerprops that have description and betType which main markets do not
export const  parseBetName = (fullName: string): { description: string; betType: string } => {
  // Handle Over/Under bets
  const overUnderMatch = fullName.match(/(Over|Under)\s+(\d+(\.\d+)?)/);
  if (overUnderMatch) {
    const betType = overUnderMatch[0];
    const description = fullName.replace(betType, '').trim();
    return { description, betType };
  }

  // Handle Yes/No bets (both with and without 0.0)
  const yesNoMatch = fullName.match(/(Yes|No)(\s+0\.0)?$/);
  if (yesNoMatch) {
    const betType = yesNoMatch[1]; // Just "Yes" or "No"
    const description = fullName.replace(yesNoMatch[0], '').trim();
    return { description, betType };
  }

  // If no specific bet type is found, return the full name as description and an empty bet type
  return { description: fullName, betType: '' };
};

export const determineFontSizeForOdds = (odds?: number): string => {
  // Convert the number to a string including the sign symbol (+ or -)
 if(!odds) return '';
  const oddsString = odds > 0 ? `+${odds}` : `${odds}`;
  
  // Determine the font size based on the length of the odds string
  if (oddsString.length >= 7) {
    return 'text-[10px]';
  } else if (oddsString.length >= 6) {
    return 'text-[12px]';
  }
  return '';
};


export interface BetTypeInfo {
  labelTop: string;
  labelBottom: string;
  point?: number;
}

function extractTeamNamesFromEvent(matchup: string): { awayTeam: string; homeTeam: string } | null {
  const regex = /^(.+?)\s*vs\.?\s*(.+?)$/i; // Captures both team names with spaces allowed

  const match = matchup.match(regex);
  if (!match) {
      console.error("Invalid format. Expected format: 'Away Team vs. Home Team'");
      return null;
  }

  // Preserve spaces inside team names; only remove leading/trailing spaces
  const cleanTeamName = (team: string) => team.trim();

  const awayTeam = cleanTeamName(match[1]);
  const homeTeam = cleanTeamName(match[2]);

  return { awayTeam, homeTeam };
}



export const getLabelsForMarketView = (mktView: any): BetTypeInfo => {

  const betType = mktView.bet_type;
  const teamNames = extractTeamNamesFromEvent(mktView.event);

  if(mktView.market_type?.toLowerCase()===MAIN_MARKETS[0]){
    return {
      labelTop: teamNames?.awayTeam? teamNames.awayTeam: "Away" ,
      labelBottom: teamNames?.homeTeam? teamNames.homeTeam: "Home" ,
      point: undefined,
    };
  }


  // Extract the point value from the bet_type string
  const pointRegex = /-?\d+(\.\d+)?/;
  const match = betType.match(pointRegex);
  
  let point = 0;
  if (match) {
      point = parseFloat(match[0]);
  }


  if(mktView.market_type?.toLowerCase()===MAIN_MARKETS[1]){
    return {
      labelTop: teamNames?.awayTeam??`${point}`,
      labelBottom:teamNames?.homeTeam??`${point}`,
      point
    };
  }


  // If the point is zero, show "Yes" at the top and "No" at the bottom
  if (point === 0) {
    return {
      labelTop: "Yes",
      labelBottom: "No",
      point,
    };
  }


  // Otherwise, show "Over" at the top and "Under" at the bottom
  return {
    labelTop: `Over ${point}`,
    labelBottom: `Under ${point}`,
    point,
  };
};



interface PlayerPropBet {
  id: number;
  description: string;
  bet_type: string;
  price: number;
  odd_id: string;
  market_type: string;
  bookmaker_key: string;
  event?: string;
  event_date?: string;
  league?: string;
  start_time?: string;
}

type PlayerPropBetMap = Record<'home' | 'away', PlayerPropBet[]>;

function convertToOddsArray(responseData: PlayerPropBetMap): [string, number | undefined, number | undefined][] {
  const result: [string, number | undefined, number | undefined][] = [];

  // Create a map to track bookies and their odds
  const bookieMap: { [key: string]: [number | undefined, number | undefined] } = {};

  // Iterate over away bets
  responseData.away.forEach((propBet, index) => {
    if (!bookieMap[propBet.bookmaker_key]) {
      bookieMap[propBet.bookmaker_key] = [propBet.price, undefined];
    } else {
      bookieMap[propBet.bookmaker_key][0] = propBet.price;
    }
  });


  // Iterate over home bets
  responseData.home.forEach((propBet, index) => {


    if (!bookieMap[propBet.bookmaker_key]) {
      bookieMap[propBet.bookmaker_key] = [undefined, propBet.price];
    } else {
      bookieMap[propBet.bookmaker_key][1] = propBet.price;
    }
  });


  // Convert the map to the result array
  Object.entries(bookieMap).forEach(([bookmaker_key, [awayPrice, homePrice]]) => {
    result.push([bookmaker_key, awayPrice, homePrice]);
  });

  return result;
}

// The method to fetch and convert market view data
export const fetchAndConvertMarketView = async (originalObject: any): Promise<[string, number | undefined, number | undefined][]> => {
  try {
    // Fetch the market view data from the server
    const response = await getMarketView(originalObject);

    if (response && (response.home || response.away) ) {
      // Convert the response data to the desired array format
      const oddsArray = convertToOddsArray(response);
      return oddsArray;
    } else {
      console.error("Failed to get valid data from market view");
      return [];
    }
  } catch (error) {
    console.error("Error fetching and converting market view:", error);
    return [];
  }
};

// Utility function to format odds for display
export function formatOdds(odds: number | null | undefined): string {
  if (!odds || odds === 0) {
    return "--";
  }
  return odds > 0 ? `+${odds}` : `${odds}`;
}

export const getBackgroundColorFromOdds = (
  fairOdds: number,
  givenOdds?: number
): string => {
  if (!givenOdds) {
    return "no-ev-gray no-ev-gray-border";
  }
  if (givenOdds > fairOdds) {
    return "ev-green ev-green-border";
  } else if (givenOdds < fairOdds) {
    return "ev-red ev-red-border";
  }
  return "no-ev-gray no-ev-gray-border";
};

export function getGreatestOdds(fetchedOdds: any) : [number,number] {
  let maxHomeBet = undefined;
  let maxAwayBet = undefined;

  for (const [, homeBet, awayBet] of fetchedOdds) {
      if (homeBet !== undefined) {
          maxHomeBet = maxHomeBet !== undefined ? Math.max(maxHomeBet, homeBet) : homeBet;
      }
      if (awayBet !== undefined) {
          maxAwayBet = maxAwayBet !== undefined ? Math.max(maxAwayBet, awayBet) : awayBet;
      }
  }

  // Ensure we return [number, number] by defaulting to 0 if either is undefined
  return [maxHomeBet ?? 0, maxAwayBet ?? 0];
}
